@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap);
/**/
:root {
	--primary-white: #ffffff;
	--primary-light-grey: #f4f4f4;
	--primary-grey: #dce1e3;
	--primary-dark-grey: #5c5f58;
	--primary-hover-red: #f9ab00;
	--primary-dark: rgb(52, 58, 64);
	--primary-red: #591c0b;
	--primary-hover-red: #ee3c2c;
	--primary-black: #000000;
	--primary-transparent-black: rgba(0, 0, 0, 0.7);
	--primary-green: green;
	/* // Red Highlight: #B73225

// Blue Minded: #004E7C

// Maroon 6: #591COB

// Grey Water: #5C5F58

// Lighter Grey: #DCE1E3 */
}

body {
	font-family: 'Roboto Condensed', sans-serif !important;
}

/************************ NAVBAR *********************************/

nav {
	z-index: 2;
}

.bg-transparent {
	background: linear-gradient(
		rgb(255, 255, 255),
		rgba(75, 75, 75, 0.39)
	) !important;
}

.logo {
	width: 15rem;
	margin-top: -5rem;
}

/*----- MENU LINKS --------*/
nav a.nav-link {
	font-size: 1rem;
	letter-spacing: 0.0375rem;
	cursor: pointer;
}

nav ul li {
	text-transform: uppercase;
}

.navbar-nav > li > a {
	margin-right: 0.75rem;
	margin-left: 0.75rem;
	color: #000000 !important;
	color: var(--primary-black) !important;
	border-top: 0.1875rem solid #000000;
	border-top: 0.1875rem solid var(--primary-black);
}

.navbar-brand {
	max-width: 17.25rem;
	padding: 0 !important;
	height: 6rem !important;
}

@media (max-width: 768px) {
	.navbar-brand {
		width: 7rem;
	}
}

.navbar-nav > li > a.active,
.navbar-nav > li > a:focus {
	border-top: 0.1875rem solid #ee3c2c;
	border-top: 0.1875rem solid var(--primary-hover-red);
}

.navbar-nav > li > a:hover {
	border-top: 0.1875rem solid #ee3c2c;
	border-top: 0.1875rem solid var(--primary-hover-red);
}

/************************ HEADER *********************************/

.header-wraper {
	position: relative;
	background: url(/static/media/keys.b54bc638.jpeg) no-repeat;
	background-size: cover;
	background-position: center;
	height: 100vh;
}

.layer {
	background-color: rgba(95, 95, 95, 0.6);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.main-info {
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	z-index: 1;
}

.main-info h1 {
	color: #ee3c2c;
	color: var(--primary-hover-red);
	font-size: 4rem;
	text-transform: uppercase;
}

/*-------- TYPED TEXT ---------------*/
.typed-text {
	font-size: 2.3rem;
	color: #ffffff;
	color: var(--primary-white);
}

@media (max-width: 768px) {
	.main-info h1 {
		font-size: 1.7rem !important;
	}
	.typed-text {
		font-size: 1.8rem !important;
	}
}

/*-------- HEADER MAIN OFFER BUTTON ---------------*/
.btn-main-offer {
	border: 1px solid #ffffff;
	border: 1px solid var(--primary-white);
	background-color: #ee3c2c;
	background-color: var(--primary-hover-red);
	text-transform: uppercase;
	border-radius: 0;
	padding: 0.625rem 0.75rem 0.75rem 0.75rem;
	color: #ffffff;
	color: var(--primary-white);
	margin: 2rem;
}

.btn-main-offer:hover {
	text-decoration: none;
	background-color: blue;
	color: #ffffff;
	color: var(--primary-white);
	transition: 0.2s ease-in-out;
}

/*-------- PARTICLES JS ---------------*/
.tsparticles-canvas-el {
	position: absolute;
	height: 100vh;
	width: 100vw;
	z-index: 1;
}

@media (max-width: 768px) {
	.particles-canvas {
		display: none;
	}
}

/************************ ABOUT *********************************/
.photo-wrap {
	display: flex;
	justify-content: center;
	align-items: center;
}

.profile-img {
	width: 25rem;
	border-radius: 10%;
	padding: 0.3rem;
	border: 1px solid #ee3c2c;
	border: 1px solid var(--primary-hover-red);
}

.about-heading {
	color: #ee3c2c;
	color: var(--primary-hover-red);
	text-transform: uppercase;
}

@media (max-width: 468px) {
	.profile-img {
		width: 10rem;
	}
}

/************************ SERVICES *********************************/
.services {
	text-align: center;
	padding-bottom: 2rem;
	background: #f4f4f4;
	background: var(--primary-light-grey);
}

.services h1 {
	color: rgb(52, 58, 64);
	color: var(--primary-dark);
	text-transform: uppercase;
}

.services .circle {
	position: relative;
	margin: 0.375rem auto;
	background: #ee3c2c;
	background: var(--primary-hover-red);
	border-radius: 50%;
	width: 3.125rem;
	height: 3.125rem;
}

.services .icon {
	font-size: 1.4rem;
	color: #ffffff;
	color: var(--primary-white);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.services .box {
	height: 16rem;
	border-bottom: 0.3125rem solid #ee3c2c;
	border-bottom: 0.3125rem solid var(--primary-hover-red);
	padding: 0.625rem;
	margin-bottom: 1.875rem;
	background: #ffffff;
	background: var(--primary-white);
	transition: 0.3s ease-in-out;
}

.services .box:hover {
	background: #ee3c2c;
	background: var(--primary-hover-red);
	border-bottom: 0.3125rem solid rgb(52, 58, 64);
	border-bottom: 0.3125rem solid var(--primary-dark);
}

.services .box:hover .icon {
	color: rgb(52, 58, 64);
	color: var(--primary-dark);
}

.services .box:hover .circle {
	background: #ffffff;
	background: var(--primary-white);
}

.services .box:hover p {
	color: #ffffff;
	color: var(--primary-white);
}

/************************ EXPERIENCE *********************************/

.experience {
	padding: 0.7rem;
}

.experience-wrapper {
	position: relative;
	padding: 3.125rem 0;
	overflow: hidden;
}

.experience h1 {
	text-transform: uppercase;
	color: #ee3c2c;
	color: var(--primary-hover-red);
}

.experience-wrapper::before {
	position: absolute;
	content: '';
	top: 0;
	left: 50%;
	margin-left: -0.0625rem;
	width: 0.125rem;
	height: 100%;
	background: #dce1e3;
	background: var(--primary-grey);
	z-index: 1;
}

.timeline-block {
	width: calc(50% + 0.5rem);
	display: flex;
	justify-content: space-between;
	clear: both;
}

.timeline-block-right {
	float: right;
}

.timeline-block-left {
	float: left;
	direction: rtl;
}

.marker {
	width: 1rem;
	height: 1rem;
	border-radius: 50%;
	border: 0.3125rem solid rgb(52, 58, 64);
	border: 0.3125rem solid var(--primary-dark);
	background: #ee3c2c;
	background: var(--primary-hover-red);
	margin-top: 0.625rem;
	z-index: 3;
}

.timeline-content {
	width: 95%;
	padding: 0 0.9375rem;
	color: #5c5f58;
	color: var(--primary-dark-grey);
	direction: ltr;
}

.timeline-content h3 {
	margin-top: 0.3125rem;
	color: #591c0b;
	color: var(--primary-red);
	font-weight: 900;
}

/*------------ MEDIA ------------------*/
@media (max-width: 768px) {
	.experience .container {
		padding-left: 0;
	}

	.experience-wrapper::before {
		left: 0.5rem;
		margin-bottom: 1.875rem;
	}
	.timeline-block-right {
		float: none;
		width: 100%;
	}

	.timeline-block-left {
		float: none;
		direction: ltr;
		width: 100%;
	}
}

/************************ PORTFOLIO *********************************/
.portfolio-wrapper {
	background: #f4f4f4;
	background: var(--primary-light-grey);
	padding: 3rem 0;
}

.portfolio-wrapper h1 {
	color: #ee3c2c;
	color: var(--primary-hover-red);
}

.portfolio-image {
	width: 15rem;
	border: 1px solid #ee3c2c;
	border: 1px solid var(--primary-hover-red);
	padding: 0 0.5rem;
}

.portfolio-image-box {
	position: relative;
	margin: 1rem;
}

/*------------ OVERFLOW BOX ------------------*/
.overflow {
	position: absolute;
	opacity: 0;
	background: #000000;
	background: var(--primary-black);
	width: 15rem;
	height: 8rem;
	top: 0;
	border: 1px solid #591c0b;
	border: 1px solid var(--primary-red);
	cursor: pointer;
}

.portfolio-image-box:hover .overflow {
	opacity: 60%;
	transition: 0.3s ease-in-out;
}

.portfolio-icon {
	position: absolute;
	opacity: 0;
	margin-left: -8rem;
	margin-top: 3.6rem;
	color: #ee3c2c;
	color: var(--primary-hover-red);
	font-size: 1.5rem;
}

.portfolio-image-box:hover .portfolio-icon {
	opacity: 70%;
	transition: 0.3s ease-in-out;
	cursor: pointer;
}

.popupbox-wrapper {
	margin-top: 7rem;
}

/*------------ OVERFLOW BOX ------------------*/
.portfolio-image-popupbox {
	width: 45rem;
	padding: 0 0.5rem;
}

.hyper-link {
	cursor: pointer;
	color: #ee3c2c;
	color: var(--primary-hover-red);
}

.hyper-link:hover {
	color: #591c0b;
	color: var(--primary-red);
}

/*------------ MEDIA ------------------*/
@media (max-width: 768px) {
	.portfolio-image-popupbox {
		width: 100%;
	}
}

@media (max-height: 570px) {
	.popupbox-wrapper {
		height: 100%;
	}
	.portfolio-image-popupbox {
		width: 50%;
	}
}

@media (max-height: 280px) {
	.popupbox-wrapper {
		height: 90%;
	}
	.portfolio-image-popupbox {
		width: 30%;
	}
}

/************************ TESTIMONIALS *********************************/
.testimonials {
	background-image: url(/static/media/testimonials-bg.75612be7.jpg);
	background-size: cover;
	background-color: #000000;
	background-color: var(--primary-black);
	text-align: center;
	padding-bottom: 4rem;
	margin-bottom: 0;
	width: 100%;
}

.testimonials h1 {
	color: #ee3c2c;
	color: var(--primary-hover-red);
	padding: 3rem 0;
	text-transform: uppercase;
}

.testimonials-content {
	text-align: center;
	max-width: 53.125rem;
	margin: 0 auto 2rem auto;
	background: rgba(0, 0, 0, 0.7);
	background: var(--primary-transparent-black);
}

.myCarousel {
	color: #ffffff;
	color: var(--primary-white);
	background: transparent;
	margin-top: -6%;
	width: 54%;
	margin-left: auto;
	margin-right: auto;
	padding-top: 6%;
	padding-bottom: 8%;
	padding-left: 5%;
	padding-right: 5%;
	height: 17.875rem;
}

.myCarousel h3 {
	color: #ee3c2c;
	color: var(--primary-hover-red);
	font-weight: 600;
	text-transform: uppercase;
}

.myCarousel p {
	color: #dce1e3;
	color: var(--primary-grey);
}

.carousel .slide img {
	width: 10rem !important;
	border-radius: 50%;
}

.carousel .slide {
	background: transparent !important;
	height: 100% !important;
	padding-top: 2rem;
}

.carousel-root {
	margin: auto !important;
	margin-top: 3% !important;
}

/************************ CONTACTS COMPONENT *********************************/
.contacts {
	background: rgb(52, 58, 64);
	background: var(--primary-dark);
	padding: 3rem 0;
}

.contacts h1 {
	color: #ee3c2c;
	color: var(--primary-hover-red);
	padding: 1.3rem 0;
	text-transform: uppercase;
}

.contacts p {
	color: #f4f4f4;
	color: var(--primary-light-grey);
	font-size: 1.2rem;
}

/*------------------ INPUTS -------------------------------*/
.contacts input,
.contacts textarea {
	width: 100%;
	box-sizing: border-box;
	background: none;
	resize: none;
	border: 0;
	border-bottom: 0.125rem solid #5c5f58;
	border-bottom: 0.125rem solid var(--primary-dark-grey);
	border-radius: 0;
	color: #ee3c2c;
	color: var(--primary-hover-red);
	outline: 0 !important;
	box-shadow: none !important;
	margin-bottom: 1rem;
	padding-left: 0 !important;
}

.contacts .container {
	padding: 4rem 0;
}

.contacts input:focus,
.contacts textarea:focus {
	background: transparent;
	color: #ee3c2c;
	color: var(--primary-hover-red);
	border-bottom: 0.125rem solid #5c5f58;
	border-bottom: 0.125rem solid var(--primary-dark-grey);
}

.contacts textarea {
	height: 12.2rem !important;
}

.contact-btn {
	margin: 1rem 0 0 0;
	width: 100%;
	background: none;
}

.line {
	position: relative;
	top: -0.8rem;
	width: 0;
	height: 0.125rem;
	background: #ee3c2c;
	background: var(--primary-hover-red);
	display: inline-block;
}

input:focus + .line,
textarea:focus + .line {
	width: 100%;
	transition: width 0.5s ease-in-out;
}

input.form-control {
	margin: -0.3rem 0;
}

textarea.form-control {
	margin: -0.3rem 0;
}

.error-message {
	color: #ffffff;
	color: var(--primary-white);
	background: #ee3c2c;
	background: var(--primary-hover-red);
	text-align: left;
}

.success-message {
	font-weight: 900;
	color: green;
	color: var(--primary-green);
}

/*---------------- MEDIA -----------------------*/
@media (max-width: 768px) {
	.contacts {
		overflow-x: hidden;
	}

	.contacts .container {
		padding: 0 1rem;
	}

	.contacts p {
		padding: 0 0.4rem;
	}
}

/************************ FOOTER COMPONENT *********************************/
.footer {
	background: #000000;
	background: var(--primary-black);
	color: #5c5f58;
	color: var(--primary-dark-grey);
	padding: 3rem;
}

.footer a {
	text-decoration: none;
	color: #5c5f58;
	color: var(--primary-dark-grey);
}

.footer a:hover {
	text-decoration: none;
	color: #ee3c2c;
	color: var(--primary-hover-red);
	cursor: pointer;
}

.footer p {
	margin-bottom: 0;
}

